import React, { useState, useEffect } from "react";
import { About } from "./about";
import { Services } from "./services";
import { Contact } from "./contact";
import JsonData from "../data/data.json";
import OEMODMData from "../data/oem.json";

import "../App.css";

export const OEMODM = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div id="oem-odm">
      <About data={OEMODMData.OEM} />
      <About data={OEMODMData.ODM} />
      <Services data={landingPageData.Services} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};
