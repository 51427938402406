import React, { useState, useEffect } from "react";

export const About = (props) => {
  const images = props.data?.images ?? [
    "img/company1.jpeg",
    "img/company2.jpeg",
    "img/company3.jpeg",
    "img/company4.jpeg",
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images?.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [images?.length]);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images?.length);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images?.length) % images?.length
    );
  };

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="carousel-wrapper">
              <button onClick={prevImage} className="carousel-control-prev">
                &lt;
              </button>
              <div className="carousel">
                <img
                  src={images[currentImageIndex]}
                  className="img-responsive"
                  alt=""
                />
              </div>
              <button onClick={nextImage} className="carousel-control-next">
                &gt;
              </button>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>{props.data?.title ?? "About Us"}</h2>
              <p>{props.data ? props.data.paragraph1 : "loading..."}</p>
              <div className="about-content-container">
                  <ul>
                    {props.data
                      ? props.data?.content?.map((d, i) => (
                          <div>
                            <h4 key={`${'content'}-${i}`}>{d.title}</h4>
                            <div className="subcontent"><p key={`${`content`}-${i}`}>{d.paragraph}</p>
                           </div>
                            <div>
                            <div className="about-subcontent-container">
                              { d.subContent?.map((x, i) => (
                                      <li key={`${'content'}-${i}`}><strong>{x.title}</strong>{x.paragraph}</li>
                                  ))
                               }
                            </div>
                            </div>
                          </div>
                        ))
                      : ""}
                  </ul>
                </div>
              <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
              <h3>{ props.data?.subtitle ? "Why Choose Us?" : ''}</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props?.data?.Why
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : ""}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props?.data?.Why
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : ""}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .carousel-wrapper {
          display: flex;
          align-items: center;
          position: relative;
        }
        .carousel {
          flex: 1;
          position: relative;
          width: 100%;
          height: 450px; /* Set a fixed height */
        }
        .carousel img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .carousel-control-prev,
        .carousel-control-next {
          background: rgba(0, 0, 0, 0.5);
          color: white;
          border: none;
          padding: 10px;
          cursor: pointer;
          font-size: 24px;
          z-index: 1;
        }
        .carousel-control-prev {
          margin-right: 10px;
        }
        .carousel-control-next {
          margin-left: 10px;
        }
        @media (max-width: 768px) {
          #about {
            padding:initial;
          }
          .carousel-wrapper {
            flex-direction: column;
            height: 320px;
            margin-bottom: 100px;
          }
          .carousel-control-prev,
          .carousel-control-next {
            font-size: 18px;
            padding: 5px;
            margin: 10;
            width: 50px;
            height: 50px;
          }
          .carousel-control-prev {
            position: absolute;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);
          }
          .carousel-control-next {
            position: absolute;
            right: -20px;
            top: 50%;
            transform: translateY(-50%);
          }
          .carousel {
            width: 90%;
            height: 320px;
            flex: 1;
            position: relative;
            margin-bottom: 30px;
          }
          .carousel img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      `}</style>
    </div>
  );
};
