import React from "react";
import JsonData from "../../data/product.json";
import { useEffect, useState } from "react";
import styles from '../../Product.module.css';

export const Products = () => {
  const [productData, setProductData] = useState([]); // Initialize with an empty array
  const [category, setCategory] = useState('all'); // State to track selected category

  useEffect(() => {
    setProductData(JsonData.products); // Ensure this matches the structure of your JSON data
  }, []);

  const filteredItems = category === 'all' ? productData : productData.filter(item => item.Category === category);

  const categories = ['all', ...new Set(productData.map(product => product.Category))];

  return (
    <div id="team" className="text-center">
      <div className="container">
      <div className="row">
        <div className={`${styles['section-title']} col-md-8 col-md-offset-2 `} >
          <h2>Our Products</h2>
          <p>
            100% silk products.
          </p>
        </div>
        </div>
        <div className="row">
          <div className="col-md-8">
        <div className={styles['filter-container']}>
          <label htmlFor="category">Filter by Category:</label>
          <select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className={styles['category-select']}
          >
            {categories.map((cat) => (
              <option key={cat} value={cat}>
                {cat}
              </option>
            ))}
          </select>
        </div>
        </div>
        </div>
        <div id="row">
          {filteredItems.length > 0
            ? filteredItems.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      {/* <p>{d.job}</p> */}
                      <button type="submit" className="btn btn-custom btn-sm">
                        Add to inquiry
                      </button>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};

export default Products;
