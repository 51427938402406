import React from "react";
import { Navigation } from "./components/navigation";
import { Home } from "./components/home";
import {Products} from "./components/products/Products"
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { OEMODM } from "./components/oemodm";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
    <Router>
      <Navigation />
      <main className="app-main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/oem-odm" element={<OEMODM />} />
          </Routes>
        </main>
    </Router>
  );
};

export default App;
