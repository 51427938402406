import React, { useState, useEffect, useMemo } from 'react';

export const Header = (props) => {
  const images = useMemo(() => [
    '../img/company/frontpagecombine1.jpg',
    '../img/company/frontpagedress1.jpg',
    '../img/company/frontpagedress2.jpg',
    '../img/company/frontpagecombine2.jpg',
    '../img/company/frontpagetops2.jpg',
    '../img/company/frontpagecombine3.jpg',
  ], []);

  const [bgImage, setBgImage] = useState(images[0]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000); // Change every 3 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  useEffect(() => {
    setBgImage(images[currentIndex]);
  }, [currentIndex, images]);

  return (
    <header id="header">
      <div
        className="intro"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover', // Cover the entire container while maintaining aspect ratio
          transition: 'background-image 0.2s ease-in-out', // Faster transition for the background image
        }}
      >
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h3>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h3>
                <div className="home-button-container">
                  <a
                    href="#features"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Learn More
                  </a>

                  <a
                    href="mailto:sales@jmsilkco.com"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Email Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
