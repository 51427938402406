import React from "react";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
     

            <a className="navbar-brand page-scroll logo-container" href="/#page-top">
              <div className="logo-area-container">
              <img className="website-logo-image" src={"img/final_logo.png"}  alt={"JM Silk"}/>
              <div>JM Silk</div>
              </div>
              </a>

        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="/" className="page-scroll">
                Home
              </a>
            </li>
            <li>
              <a href="/products" className="page-scroll">
                Products
              </a>
            </li>
            <li>
              <a href="/oem-odm" className="page-scroll">
                OEM/ODM
              </a>
            </li>
            <li>
              <a href="/#about" className="page-scroll">
                About US
              </a>
            </li>
            <li>
              <a href="/#services" className="page-scroll">
                Services
              </a>
            </li>
            <li>
              <a href="/#portfolio" className="page-scroll">
                ShowRoom
              </a>
            </li>
            <li>
              <a href="/#contact" className="page-scroll">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
